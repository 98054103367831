import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionCheckPipe } from '@app/pipes/permission-flag.pipe';
import { TeamService } from '@app/reducers/orm/team/team.service';
import { TranslateService } from '@ngx-translate/core';
import { SbDialogService } from '@sb/ui';
import { catchError, EMPTY, of, switchMap, take } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[appDeleteTeam]',
  providers: [PermissionCheckPipe],
})
export class DeleteTeamDirective {
  @Input()
  public set appDeleteTeam(context: { teamId: string; departmentId: string }) {
    this.teamId = context.teamId;
    this.departmentId = context.departmentId;

    if (!this.teamId || !this.departmentId) {
      throw new Error('Team ID and Department ID are required in appDeleteTeam directive');
    }
  }

  @HostBinding('class')
  public readonly class = 'cursor-pointer';

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent) {
    event.stopPropagation();
    this.removeTeam();
  }

  private teamId: string;
  private departmentId: string;

  public constructor(
    private dialog: SbDialogService,
    private router: Router,
    private route: ActivatedRoute,
    private permissionCheck: PermissionCheckPipe,
    private teamService: TeamService,
    private translate: TranslateService,
  ) {}

  public removeTeam() {
    const hasMoveEmployeesPermission = this.permissionCheck.transform(['Edit users', 'Manage account']);

    void this.teamService
      .validateRemove(this.teamId)
      .pipe(
        catchError((error) => {
          if (error.status === 422 && hasMoveEmployeesPermission) {
            this.triggerTeamDeletionFlow(this.teamId);
          }
          return EMPTY;
        }),
        switchMap(() => this.confirmRemoveTeamDialog()),
        switchMap((result) => {
          if (result?.confirmed) {
            return this.teamService.remove(this.teamId, this.departmentId);
          }

          return of(null);
        }),
        take(1),
      )
      .subscribe(() => {
        if (this.route.outlet === 'modal') {
          this.dialog.closeAll();
        }
      });
  }

  private confirmRemoveTeamDialog() {
    return this.dialog.openConfirm({
      type: 'danger',
      title: this.translate.instant('Delete This Team?'),
      description: this.translate.instant(
        `Deleting this team will remove all associated schedules. The team's timesheet data will be preserved. This action cannot be undone. Are you sure you want to continue?`,
      ),
      primary: {
        text: this.translate.instant('Delete team'),
      },
    }).closed;
  }

  private triggerTeamDeletionFlow(teamId: string) {
    if (this.route.outlet === 'modal') {
      this.dialog.closeAll();
    }
    this.router.navigate(['', { outlets: { modal: ['delete-team', teamId] } }]);
  }
}
